<template>
  <div>
    <header class="mb30">
        <el-date-picker
          v-model="timeArr"
          value-format="timestamp"
          type="datetimerange"
          range-separator="至"
          start-placeholder="开始时间"
          end-placeholder="结束时间">
      </el-date-picker>
      <el-button type="primary" class="ml32" @click="mileageStatistics">搜索</el-button>
    </header>

      <footer>
         <el-table
            :data="tableData"
            stripe
            style="width: 100%">
            <el-table-column prop="deviceNo" label="设备号" width="180"/>
            <el-table-column prop="currentMileage" label="当前里程数(KM)" width="180">
               <template slot-scope="scope">
                  <span>{{scope.row.currentMileage}}KM</span>
                </template>
            </el-table-column>
            <el-table-column
              prop="dailyMileage"
              label="今日里程数(KM)">
               <template slot-scope="scope">
                  <span>{{scope.row.dailyMileage}}KM</span>
                </template>
            </el-table-column>
            <el-table-column
              prop="travelTime"
              label="行驶时间(小时)">
               <template slot-scope="scope">
                  <span>{{scope.row.travelTime}}小时</span>
                </template>
            </el-table-column>
            <el-table-column
              prop="maxSpeed"
              label="最高时速(KM/H)">
               <template slot-scope="scope">
                  <span>{{scope.row.maxSpeed}}KM/H</span>
                </template>
            </el-table-column>
            <el-table-column
              prop="date"
              label="日期">
               <template slot-scope="scope">
                  <span>{{scope.row.date}}</span>
                </template>
            </el-table-column>
          </el-table>
      </footer>


  </div>
</template>

<script>
import {mileageStatistics} from "@/api/facility";
export default {
  data() {
    return {
      timeArr:[],
      form:{
        haiLiDeviceNo:'',
        startTime:'',
        endTime:'',
      },
        tableData: [],
    }
  },
  beforeDestroy() {
    document.onkeydown = null
  },
  mounted(){
    const _this = this;
    document.onkeydown = function(e) {
      let key = window.event.keyCode;
      if (key == 13) {
        _this.mileageStatistics()
      }
    };
    this.mileageStatistics();
  },
  methods: {
     async mileageStatistics(){
      try{
        if(this.timeArr && this.timeArr.length > 0){
           this.form.startTime = this.timeArr[0];
           this.form.endTime = this.timeArr[1];
        }else{
           this.form.startTime = new Date().getTime() - 7000*60*60*24;
           this.form.endTime = new Date().getTime();
           this.timeArr = [];
           this.timeArr.push(this.form.startTime,this.form.endTime)
        }
        this.form.haiLiDeviceNo = this.$route.query.haiLiDeviceNo;
        // this.form.haiLiDeviceNo = '1616469075814826498';
        const params = {...this.form};
        const res = await mileageStatistics(params);
        this.tableData = res.ldata;
      }catch(e){
        console.log(e,'e');
      }
    }
  },
  components: {

  }
}
</script>

<style scoped>

</style>
